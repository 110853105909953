import React, { useContext } from "react";

import styled from "styled-components";

import Error404 from "../../src/svg/error404.svg";
import Error404Dark from "../../src/svg/error404-dark.svg";
import { Context } from "../../src/components/Layout/index";

const NotFound = () => {
  const { currentTheme } = useContext(Context) || {};

  return (
    <Wrapper>
      {currentTheme === "light" ? <Error404 /> : <Error404Dark />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    max-width: 600px;
  }
`;

export default NotFound;
